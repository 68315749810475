/**
 * @module Sagas/Pages
 */
import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { FormService } from 'modules/api/index'
import { ActionTypes } from 'constants/index';
import { dispatch } from 'd3';
import { toast } from 'react-toastify';
import FileDownload from "js-file-download";

/**
 * Application
 *
 * @param {Object} action
 *
 */

async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function* getEntryForms(action) {
    try {
        console.log("action", action);
        let forms = yield fetchAsync(FormService.getEntryForms, action);
        yield put({ type: ActionTypes.GET_FORMS_SUCCESS, payload: forms });

    } catch (e) {
        yield put({ type: ActionTypes.GET_FORMS_ERROR, error: e.message });
    }
}

function* getEntryFormByName(action) {
    try {
        console.log("action", action);
        let form = yield fetchAsync(FormService.getFormByName, action);
        let events = yield fetchAsync(FormService.getWorkflowEvents, { workflowId: form[0].workflowId, appId: action.appId });
        if (action.submissionId) {
            const submittedForm = yield fetchAsync(FormService.getSubmittedDataBySubmissionId, { id: action.submissionId, formName: action.formName, appId: action.appId });
            yield put({ type: ActionTypes.GET_FORM_BY_NAME_SUCCESS, payload: { form: form && form[0], events } });
            yield put({ type: ActionTypes.GET_SUBMITTED_DATA_BY_ID_SUCCESS, payload: submittedForm });
        } else {
            yield put({ type: ActionTypes.GET_FORM_BY_NAME_SUCCESS, payload: { form: form && form[0], events } });
        }

    } catch (e) {
        yield put({ type: ActionTypes.GET_FORM_BY_NAME_ERROR, error: e.message });
    }
}

function* getSubmittedDataBySubmissionId(action) {
    try {
        const form = yield fetchAsync(FormService.getSubmittedDataBySubmissionId, { id: action.id, formName: action.formName, appId: action.appId });
        yield put({ type: ActionTypes.GET_SUBMITTED_DATA_BY_ID_SUCCESS, payload: form });
    } catch (e) {

        console.error("error===", e);
        yield put({ type: ActionTypes.GET_SUBMITTED_DATA_BY_ID_ERROR, error: e.message });
    }
}

function* saveSubmittedData(action) {
    try {
        if (action.files.length > 0) {
            const imagesName = [];
            const formData = new FormData();
            let files = action.files
            for (let i = 0; i < files.length; i++) {
                imagesName.push(files[i].name);
                formData.append("file", files[i].file);
            }
            const fileUpload = yield fetchAsync(FormService.saveUploadedFile, { files: formData, appId: action.data.appId });
            let actionData = []
            for (let j = 0; j < fileUpload.data.files.length; j++) {
                let uploadedImageId = fileUpload.data.files[j].filename
                actionData = action.data
                actionData.data[imagesName[j]] = uploadedImageId
            }
            const form = yield fetchAsync(FormService.saveSubmittedData, { formData: actionData });
            yield put({ type: ActionTypes.SAVE_SUBMITTED_DATA_SUCCESS, payload: form });
            yield put({ type: ActionTypes.SHOW_MESSAGE, message: 'Form submitted successfully!!' });
        } else {
            const form = yield fetchAsync(FormService.saveSubmittedData, { formData: action.data });
            yield put({ type: ActionTypes.SAVE_SUBMITTED_DATA_SUCCESS, payload: form });
            yield put({ type: ActionTypes.SHOW_MESSAGE, message: 'Saving data....', description: 'The data has beend saved successful' });
        }
    } catch (e) {
        console.error('error=> ', e);
        yield put({ type: ActionTypes.SAVE_SUBMITTED_DATA_ERROR, error: e.message });
    }
}

function* updateSubmittedData(action) {
    console.log("==============updateSubmittedData", action)
    try {
        if (action.files.length > 0) {
            const imagesName = [];
            const formData = new FormData();
            let files = action.files
            for (let i = 0; i < files.length; i++) {
                imagesName.push(files[i].name);
                formData.append("file", files[i].file);
            }
            const fileUpload = yield fetchAsync(FormService.saveUploadedFile, { files: formData, appId: action.data.appId });
            let actionData = []
            for (let j = 0; j < fileUpload.data.files.length; j++) {
                let uploadedImageId = fileUpload.data.files[j].filename
                actionData = action.data
                actionData.data[imagesName[j]] = uploadedImageId
            }
            const form = yield fetchAsync(FormService.updateSubmittedData, actionData);
            yield put({ type: ActionTypes.SAVE_SUBMITTED_DATA_SUCCESS, payload: form });
            yield put({ type: ActionTypes.SHOW_MESSAGE, message: 'Form updated successfully!!' });
        } else {
            const form = yield fetchAsync(FormService.updateSubmittedData, action.data);
            yield put({ type: ActionTypes.SAVE_SUBMITTED_DATA_SUCCESS, payload: form });
            yield put({ type: ActionTypes.SHOW_MESSAGE, message: 'Saving data....', description: 'The data has beend saved successful' });
        }
    } catch {
        yield put({ type: ActionTypes.SAVE_SUBMITTED_DATA_ERROR, error: e.message });
    }
}

function* getCognitiveSearchData(action) {
    try {
        const response = yield fetchAsync(FormService.getCognitiveSearchDataFromAPI, action.payload);
        yield put({ type: ActionTypes.GET_COGNITIVE_SEARCH_DATA_SUCCESS, payload: { data: response?.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_COGNITIVE_SEARCH_DATA_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
    }
}

function* getModelData(action) {
    try {
        const response = yield fetchAsync(FormService.getModelDataFromAPI, action.payload);
        yield put({ type: ActionTypes.GET_MODAL_DATA_SUCCESS, payload: { data: response?.data, dataUniqueId: action.payload.dataUniqueId } });
    }
    catch (e) {
        yield put({ type: ActionTypes.GET_MODAL_DATA_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
    }
}

function* getModelDataDropDown(action) {
    try {
        const response = yield fetchAsync(FormService.getModelDataForFilter, action.payload);
        yield put({ type: ActionTypes.GET_MODAL_DROP_DOWN_DATA_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    }
    catch (e) {
        yield put({ type: ActionTypes.GET_MODAL_DROP_DOWN_DATA_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
    }
}

function* getCognitiveSearchV1Data(action) {
    try {
        const response = yield fetchAsync(FormService.getCognitiveSearchV1Data, action.payload);
        yield put({ type: ActionTypes.GET_COGNITIVE_SEARCH_DATA_V1_SUCCESS, payload: { data: response?.data, dataUniqueId: action.payload.dataUniqueId } });
    }
    catch (e) {
        yield put({ type: ActionTypes.GET_COGNITIVE_SEARCH_DATA_V1_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
    }
}

function* saveSmartSearchData(action) {
    try {
        const response = yield fetchAsync(FormService.saveAdvancedSearchData, action.payload);
        yield put({
            type: ActionTypes.SAVE_SMART_SEARCH_DATA_SUCCESS,
            payload: { data: response?.data, dataUniqueId: action.payload.dataUniqueId }
        });
    }
    catch (e) {
        yield put({
            type: ActionTypes.SAVE_SMART_SEARCH_DATA_FAILURE,
            payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId }
        });
    }
}

function* getAllSavedSmartSearchData(action) {
    try {
        const response = yield fetchAsync(FormService.getAllSavedSearchData, action.payload);
        yield put({
            type: ActionTypes.GET_ALL_SMART_SEARCH_DATA_SUCCESS,
            payload: { data: response?.data, dataUniqueId: action.payload.dataUniqueId }
        });
    }
    catch (e) {
        yield put({
            type: ActionTypes.GET_ALL_SMART_SEARCH_DATA_FAILURE,
            payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId }
        });
    }
}

function* uploadContractPDFAction(action) {
    try {
        const response = yield fetchAsync(FormService.uploadContractPDFData, {
            // formData,
            // appId: action.payload.appId,
            // dataModelId: action.payload.dataModelId,
            ...action.payload
        });
        yield put({ type: ActionTypes.SUBMIT_CONTRACT_PDF_SUCCESS, payload: response.data });
    } catch (e) {
        console.log("e.==", e);
        toast.error(e.response?.data?.message || 'Unable to upload. Please try again after sometime')

        let errorCode = e.response?.error || e.response?.data?.error
        if (errorCode === "DMDUP001") {
            yield put({ type: ActionTypes.SUBMIT_CONTRACT_PDF_DUPLICATE_ERROR, payload: e.response?.data });
        } else {
            yield put({ type: ActionTypes.SUBMIT_CONTRACT_PDF_ERROR, error: e.response?.data });
        }

    }
}

function* downloadContractPDFAction(action) {
    try {
        const pdf = yield fetchAsync(
            action.payload.isDeleted ? FormService.downloadDelDataInPDF : FormService.downloadContractPDFData, action.payload);
        yield put({ type: ActionTypes.DOWNLOAD_PDF_ACTION_SUCCESS, payload: { pdf, dataUniqueId: action.payload.dataUniqueId, docId: action.payload._id, isDownload: action.payload.isDownload, pdfLoadRefresh: action.payload.pdfLoadRefresh, isEmailBody: action.payload.isEmailBody } });
    } catch (e) {
        console.log("e =====-======", e)
        let msg = "PDF document download is failed";
        if (action.payload._id) {
            msg += ' ' + action.payload._id
        }
        if (action.payload.field === 'emailBodyPath') {
            msg = "No Email Body as this document is uploaded manually by the user"
        }
        yield put({
            type: ActionTypes.DOWNLOAD_PDF_ACTION_FAILURE, payload: {
                // error: e.message || "PDF document download is failed",
                error: msg || e.message,
                docId: action.payload._id,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    }
}

function* deleteContractPDFAction(action) {
    try {
        const response = yield fetchAsync(FormService.deleteContractPDFData, action.payload);
        toast.success(response?.data?.message || 'Deleted sucessfully')
        let d = response.data;
        if (d.message && !d.ids) {
            d['ids'] = action.payload.ids.ids
        }
        yield put({ type: ActionTypes.DELETE_CONTRACT_PDF_SUCCESS, payload: { data: d, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.DELETE_CONTRACT_PDF_ERROR, error: e ? e.message : 'Unable to delete' });
    }
}

function* getContractData(action) {
    try {
        const response = yield fetchAsync(FormService.contractData, action.payload);
        yield put({ type: `${ActionTypes['GET_DOCUMENT_DATA_SUCCESS']}_${action.payload?.dataUniqueId}`, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_DOCUMENT_DATA_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });

    }
}

function* getLineItemsTablesData(action) {
    try {
        const response = yield fetchAsync(FormService.getLineItemsTablesData, action.payload);
        yield put({
            type: ActionTypes.GET_LINEITEM_TABLES_DATA_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.GET_LINEITEM_TABLES_DATA_FAILURE,
            payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId }
        });
    }
}

function* getDocumentTablesData(action) {
    try {
        const response = yield fetchAsync(FormService.getDocumentTablesData, action.payload);
        yield put({
            type: `${ActionTypes['GET_DOCUMENT_TABLES_DATA_SUCCESS']}_${action.payload?.dataUniqueId}`,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId,
                documentId: action.payload.id
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.GET_DOCUMENT_TABLES_DATA_FAILURE,
            payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId }
        });
    }
}

function* createDocumentTableData(action) {
    try {
        const response = yield fetchAsync(FormService.createDocumentTableData, action.payload);
        toast.success(response?.data?.message || 'Document created successfully');
        yield put({
            type: `${ActionTypes['CREATE_DOCUMENT_TABLES_DATA_SUCCESS']}_${action.payload?.dataUniqueId}`,
            payload: {
                data: response?.data,
                dataUniqueId: action?.payload?.dataUniqueId,
                documentId: action.payload.payload.documentId
            },
        });
    } catch (e) {
        yield put({
            type: ActionTypes.CREATE_DOCUMENT_TABLES_DATA_FAILURE,
            payload: { error: e.message, dataUniqueId: action?.payload?.dataUniqueId },
        });
        console.log(e);
    }
}

function* updateDocumentTableData(action) {
    try {
        const response = yield fetchAsync(FormService.updateDocumentTableData, action.payload);
        toast.success(response?.data?.message || 'Document updated successfully');
        yield put({
            type: ActionTypes.UPDATE_DOCUMENT_TABLES_DATA_SUCCESS,
            payload: { data: response?.data, dataUniqueId: action?.payload?.dataUniqueId },
        });
    } catch (e) {
        yield put({
            type: ActionTypes.UPDATE_DOCUMENT_TABLES_DATA_FAILURE,
            payload: { error: e.message, dataUniqueId: action?.payload?.dataUniqueId },
        });
        console.log(e);
    }
}

function* deleteDocumentTableData(action) {
    try {
        const response = yield fetchAsync(FormService.deleteDocumentTableData, action.payload);
        toast.success(response?.data?.message || 'Document deleted successfully');
        yield put({
            type: ActionTypes.DELETE_DOCUMENT_TABLE_DATA_SUCCESS,
            payload: { data: response?.data, dataUniqueId: action?.payload?.dataUniqueId },
        });
    } catch (e) {
        yield put({
            type: ActionTypes.DELETE_DOCUMENT_TABLE_DATA_FAILURE,
            payload: { error: e.message, dataUniqueId: action?.payload?.dataUniqueId },
        });
        console.log(e);
    }
}

function* downloadSheet(action) {
    console.log('action:', action?.payload?.isDeleted, action?.payload)
    try {
        const response = yield fetchAsync(action?.payload?.isDeleted ? action?.payload?.downloadType === 'bulk' ? FormService?.downloadDelDataAllInPDF : FormService.downloadDelDataInPDF : action.payload.isDuplicate ? action?.payload?.downloadType === 'bulk' ? FormService?.downloadDelDataAllInPDF : FormService.downloadDuplicateDoc : FormService.downloadDataInSheet, action.payload);
        yield put({ type: ActionTypes.DOWNLOAD_SHEET_SUCCESS, payload: { data: response.data, dataUniqueId: action?.payload?.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.DOWNLOAD_SHEET_FAILURE, payload: { error: e.message, dataUniqueId: action?.payload?.dataUniqueId } });
        console.log(e);
    }
}

function* updateDataModelData(action) {
    try {
        const response = yield fetchAsync(FormService.updateDataModelData, action.payload);
        console.log("it is response =======", response)
        if (response.error && response.error !== '0') {
            yield put({
                type: ActionTypes.UPDATE_DATA_MODAL_DATA_FAILURE,
                payload: { ...response, dataUniqueId: action?.payload?.dataUniqueId }
            });
        } else {
            toast.success(response?.data?.message || 'Document updated successfully')
            yield put({
                type: ActionTypes.UPDATE_DATA_MODAL_DATA_SUCCESS,
                payload: { data: response?.data, dataUniqueId: action?.payload?.dataUniqueId }
            });
        }
    } catch (e) {
        yield put({
            type: ActionTypes.UPDATE_DATA_MODAL_DATA_FAILURE,
            payload: {
                error: e.message, dataUniqueId: action?.payload?.dataUniqueId
            }
        });
        console.log(e);
    }
}

function* createNewDocument(action) {
    try {
        const response = yield fetchAsync(FormService.createDocument, action.payload);
        yield put({ type: ActionTypes.CREATE_DOCUMENT_SUCCESS, payload: { data: response?.data, dataUniqueId: action?.payload?.dataUniqueId } });
    } catch (e) {
        console.log("it is error in create document ===", e)
        console.log("it is error in create document dataaa ===", e.data)
        yield put({ type: ActionTypes.CREATE_DOCUMENT_FAILURE, payload: { error: e.message, dataUniqueId: action?.payload?.dataUniqueId } });
    }
}

function* getDataModelById(action) {
    try {
        const response = yield fetchAsync(FormService.getDataModelById, action.payload);
        yield put({ type: ActionTypes.GET_DATA_MODEL_BY_ID_SUCCESS, payload: { data: response?.data, dataUniqueId: action?.payload?.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_DATA_MODEL_BY_ID_FAILURE, payload: { error: e.message, dataUniqueId: action?.payload?.dataUniqueId } });
        console.log(e);

    }
}

function* getLineData(action) {
    try {
        const response = yield fetchAsync(FormService.getLineData, action.payload);
        yield put({ type: ActionTypes.GET_LINE_DATA_BY_ID_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_LINE_DATA_BY_ID_FAILURE, payload: { error: e.message, dataUniqueId: action?.payload?.dataUniqueId } });
        console.log(e);
    }
}

function* getBarData(action) {
    try {
        const response = yield fetchAsync(FormService.getBarData, action.payload);
        yield put({ type: ActionTypes.GET_BAR_DATA_BY_ID_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_BAR_DATA_BY_ID_FAILURE, payload: { error: e.message, dataUniqueId: action?.payload?.dataUniqueId } });
        console.log(e);
    }
}


function* updateDataModelById(action) {
    try {
        const response = yield fetchAsync(FormService.updateDataModelById, action.payload);
        toast.info('Successfully updated')
        yield put({ type: ActionTypes.UPDATE_DATA_MODEL_BY_ID_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.UPDATE_DATA_MODEL_BY_ID_FAILURE, payload: { error: e.message, dataUniqueId: action?.payload?.dataUniqueId } });
        console.log(e);

    }
}

function* saveDataForTraining(action) {
    try {
        const response = yield fetchAsync(FormService.saveDataForTraining, action.payload);
        toast.info('Data saved for training successfully.')
        yield put({ type: ActionTypes.SAVE_DATA_FOR_TRAINING_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.SAVE_DATA_FOR_TRAINING_FAILURE, payload: { error: e.message, dataUniqueId: action?.payload?.dataUniqueId } });
        console.log(e);
    }
}

function* getTopSuggestions(action) {
    try {
        const response = yield fetchAsync(FormService.getTopSuggestions, action.payload);
        yield put({ type: ActionTypes.GET_TOP_SUGGESTIONS_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: ActionTypes.GET_TOP_SUGGESTIONS_FAILURE, payload: null });
        console.log(e);
    }
}

function* getSearchResults(action) {
    try {
        const response = yield fetchAsync(FormService.getSearchResults, action.payload);
        yield put({ type: ActionTypes.ON_SEARCH_TEXT_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });

    } catch (e) {
        yield put({ type: ActionTypes.ON_SEARCH_TEXT_FAILURE, payload: { error: e.message, dataUniqueId: action?.payload?.dataUniqueId } });
        console.log(e);
    }
}

function* getTasksList(action) {
    try {
        const response = yield fetchAsync(FormService.getTasks, action.payload);
        yield put({ type: ActionTypes.GET_TASKS_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });

    } catch (e) {
        yield put({ type: ActionTypes.GET_TASKS_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
    }
}

function* getTaskData(action) {
    try {
        const response = yield fetchAsync(FormService.getTaskData, action.payload);
        yield put({ type: ActionTypes.GET_TASK_DATA_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });

    } catch (e) {
        yield put({ type: ActionTypes.GET_TASK_DATA_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* updateTaskData(action) {
    try {
        const response = yield fetchAsync(FormService.updateTaskData, action.payload);
        yield put({ type: ActionTypes.UPDATE_TASK_DATA_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
        toast.info(response.data?.message || 'Task updated successfully')
    } catch (e) {
        yield put({ type: ActionTypes.UPDATE_TASK_DATA_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* deleteTaskData(action) {
    try {
        const response = yield fetchAsync(FormService.deleteTaskData, action.payload);
        yield put({ type: ActionTypes.DELETE_TASK_DATA_SUCCESS, payload: response.data });
        toast.info(response.data?.message || 'Task deleted successfully')
    } catch (e) {
        yield put({ type: ActionTypes.DELETE_TASK_DATA_FAILURE, error: e ? e.message : 'Something went wrong' });
    }
}
function* getMembersList(action) {
    try {
        const response = yield fetchAsync(FormService.getMembersList, action.payload);
        yield put({ type: ActionTypes.GET_MEMBERS_LIST_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        toast.error(errorMessage)
        yield put({
            type: ActionTypes.GET_MEMBERS_LIST_FAILURE,
            payload: {
                error: errorMessage,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
        console.log(e);
    }
}

function* getAllMembersList(action) {
    try {
        const response = yield fetchAsync(FormService.getAllMembersList, action.payload);
        yield put({
            type: ActionTypes.GET_ALL_MEMBERS_LIST_SUCCESS,
            payload: {
                data: {
                    data: response.data,
                    total: response.count
                },
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.GET_ALL_MEMBERS_LIST_FAILURE,
            payload: { error: e, dataUniqueId: action.payload.dataUniqueId }
        });
        console.log(e);
    }
}


function* processAgain(action) {
    try {
        const response = yield fetchAsync(FormService.processAgain, action.payload);
        yield put({ type: ActionTypes.PROCESS_AGAIN_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
        toast.info(response?.message || 'Sent for processing again')

    } catch (e) {
        yield put({ type: ActionTypes.PROCESS_AGAIN_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });

        console.log(e);
    }
}

function* getAuditTrailsData(action) {
    try {
        const response = yield fetchAsync(FormService.getAuditTrailsData, action.payload);
        yield put({ type: ActionTypes.GET_AUDIT_TRAILS_DATA_SUCCESS, payload: { data: response?.data, dataUniqueId: action.payload.dataUniqueId } });

    } catch (e) {
        yield put({ type: ActionTypes.GET_AUDIT_TRAILS_DATA_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getOthersAuditTrailsData(action) {
    try {
        const response = yield fetchAsync(FormService.getOthersAuditTrailsData, action.payload);
        yield put({ type: ActionTypes.GET_OTHERS_AUDIT_TRAILS_DATA_SUCCESS, payload: { data: response?.data, dataUniqueId: action.payload.dataUniqueId } });

    } catch (e) {
        yield put({ type: ActionTypes.GET_OTHERS_AUDIT_TRAILS_DATA_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getHistoryData(action) {
    try {
        const response = yield fetchAsync(FormService.getHistoryData, action.payload);
        yield put({ type: ActionTypes.GET_HISTORY_DATA_SUCCESS, payload: { data: response?.data, dataUniqueId: action.payload.dataUniqueId } });

    } catch (e) {
        yield put({ type: ActionTypes.GET_HISTORY_DATA_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* bulkUploadPDF(action) {
    try {
        const response = yield fetchAsync(FormService.bulkUploadPDF, action.payload);
        yield put({ type: ActionTypes.BULK_UPLOAD_PDF_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });

    } catch (e) {
        yield put({ type: ActionTypes.BULK_UPLOAD_PDF_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* bulkUploadPDFFinal(action) {
    try {
        const response = yield fetchAsync(FormService.bulkUploadPDFFinal, action.payload);
        yield put({ type: ActionTypes.BULK_UPLOAD_PDF_MERGE_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });

    } catch (e) {
        yield put({ type: ActionTypes.BULK_UPLOAD_PDF_MERGE_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* serviceBusAction(action) {
    try {
        yield put({ type: ActionTypes.SERVICE_BUS_DATA_SUCCESS, payload: action.payload });
    } catch (e) {
        yield put({ type: ActionTypes.SERVICE_BUS_DATA_FAILURE, payload: { error: e ? e.message : 'Something went wrong' } });
        console.log(e);
    }
}

function* serviceBusStatusUpdateTileList(action) {
    try {
        yield put({ type: ActionTypes.SERVICE_BUS_TITLE_LIST_STATUS_SUCCESS, payload: action.payload });
    } catch (e) {
        yield put({ type: ActionTypes.SERVICE_BUS_TITLE_LIST_STATUS_FAILURE, payload: { error: e ? e.message : 'Something went wrong' } });
        console.log(e);
    }
}

function* changeAssigneeRequest(action) {
    try {
        const response = yield fetchAsync(FormService.changeAssigneeRequest, action.payload);
        // toast.success(response.data?.message || 'Assigned successfully')
        yield put({
            type: ActionTypes.ASSIGNEE_CHANGE_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId,
                documentId: action.payload?.documentId || ''
            }
        });
    } catch (e) {
        let errorMessage = "Something went wrong"
        if (e?.response?.message || e?.response?.data?.message) {
            errorMessage = e?.response?.message || e?.response?.data?.message
        }
        yield put({
            type: ActionTypes.ASSIGNEE_CHANGE_FAILURE,
            payload: {
                error: { error: errorMessage },
                dataUniqueId: action.payload.dataUniqueId,
                documentId: action.payload?.documentId || ''
            }
        });
        console.log(e);
    }
}

function* completeReviewAction(action) {
    try {
        const response = yield fetchAsync(FormService.completeReviewAction, action.payload);
        toast.success('Quality Review completed')
        yield put({ type: ActionTypes.COMPLETE_REVIEW_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        // console.log("cmng here  comp==>>>>", e, e?.response?.message);
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Unable to complete Quality Review"
        toast.error(errorMessage)
        yield put({ type: ActionTypes.COMPLETE_REVIEW_FAILURE, payload: { error: e ? e.message : 'Unable to complete Quality Review', dataUniqueId: action.payload.dataUniqueId } });
    }
}

function* rejectReviewAction(action) {
    try {
        const response = yield fetchAsync(FormService.rejectFlow, action.payload);
        toast.success('Quality Review rejected')
        yield put({ type: ActionTypes.REJECT_REVIEW_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        toast.error(e ? e.message : 'Unable to reject Quality Review')
        yield put({ type: ActionTypes.REJECT_REVIEW_FAILURE, payload: { error: e ? e.message : 'Unable to reject Quality Review', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* createMember(action) {
    try {
        const response = yield fetchAsync(FormService.createMember, action.payload);
        yield put({ type: ActionTypes.CREATE_MEMBER_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        toast.error(errorMessage)
        yield put({ type: ActionTypes.CREATE_MEMBER_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getRolesMembers(action) {
    try {
        const response = yield fetchAsync(FormService.getRolesMembers, action.payload);
        yield put({ type: ActionTypes.GET_ROLES_MEMBERS_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_ROLES_MEMBERS_SUCCESS, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getGroups(action) {
    try {
        const response = yield fetchAsync(FormService.getGroups, action.payload);
        yield put({ type: ActionTypes.GET_GROUPS_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_GROUPS_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* deleteMember(action) {
    try {
        const response = yield fetchAsync(FormService.deleteMember, action.payload);
        yield put({ type: ActionTypes.DELETE_MEMBER_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.DELETE_MEMBER_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getWorkflow(action) {
    try {
        const response = yield fetchAsync(FormService.getWorkflow, action.payload);
        yield put({ type: ActionTypes.GET_WORKFLOW_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_WORKFLOW_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getNextTasks(action) {
    try {
        const response = yield fetchAsync(FormService.getNextTasks, action.payload);
        yield put({ type: ActionTypes.GET_NEXT_TASKS_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_NEXT_TASKS_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* executeWorkFlow(action) {
    try {
        const response = yield fetchAsync(FormService.executeWorkFlow, action.payload);
        if (response?.error && response?.error !== '0') {
            yield put({
                type: ActionTypes.EXECUTE_WORKFLOW_FAILURE,
                payload: { dataUniqueId: action.payload.dataUniqueId, data: response }
            });
        } else {
            yield put({
                type: ActionTypes.EXECUTE_WORKFLOW_SUCCESS,
                payload: { data: response, dataUniqueId: action.payload.dataUniqueId }
            });
        }
    } catch (e) {
        yield put({
            type: ActionTypes.EXECUTE_WORKFLOW_FAILURE,
            payload: {
                error: e ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId,
                data: e.response
            }
        });
        console.log("e.response ====", e.response);
    }
}

function* initWorkFlow(action) {
    try {
        const response = yield fetchAsync(FormService.initWorkFlow, action.payload);
        yield put({ type: ActionTypes.INIT_WORKFLOW_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.INIT_WORKFLOW_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getChargeCodes(action) {
    try {
        const response = yield fetchAsync(FormService.getChargeCodes, action.payload);
        yield put({ type: ActionTypes.GET_CHARGE_CODES_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_CHARGE_CODES_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getNextRespondent(action) {
    try {
        const response = yield fetchAsync(FormService.getNextRespondent, action.payload);
        yield put({ type: ActionTypes.GET_NEXT_RESPONDENT_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_NEXT_RESPONDENT_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getCurrentStepRespondents(action) {
    try {
        const response = yield fetchAsync(FormService.getCurrentStepRespondents, action.payload);
        yield put({ type: ActionTypes.GET_CURRENT_STEP_RESPONDENT_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_CURRENT_STEP_RESPONDENT_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getAppConfig(action) {
    try {
        const response = yield fetchAsync(FormService.getAppConfig, action.payload);
        yield put({ type: ActionTypes.GET_APP_CONFIG_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_APP_CONFIG_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* saveAppMngmtConfig(action) {
    try {
        const response = yield fetchAsync(FormService.saveAppMngmtConfig, action.payload);
        toast.success(response.data?.message || 'App configuration updated')
        yield put({ type: ActionTypes.SAVE_APP_CONFIG_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.SAVE_APP_CONFIG_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* createGroup(action) {
    try {
        const response = yield fetchAsync(FormService.createGroup, action.payload);
        yield put({ type: ActionTypes.GROUPS_CREATE_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GROUPS_CREATE_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}
function* deleteGroup(action) {
    try {
        const response = yield fetchAsync(FormService.deleteGroup, action.payload);
        yield put({ type: ActionTypes.DELETE_GROUPS_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.DELETE_GROUPS_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getChargeCode(action) {
    try {
        const response = yield fetchAsync(FormService.getChargeCode, action.payload);
        yield put({ type: ActionTypes.GET_CHARGE_CODE_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_CHARGE_CODE_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* createChargeCode(action) {
    try {
        const response = yield fetchAsync(FormService.createChargeCode, action.payload);
        yield put({ type: ActionTypes.CREATE_CHARGE_CODE_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.CREATE_CHARGE_CODE_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* deleteChargeCode(action) {
    try {
        const response = yield fetchAsync(FormService.deleteChargeCode, action.payload);
        yield put({ type: ActionTypes.DELETE_CHARGE_CODE_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.DELETE_CHARGE_CODE_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* archivedData(action) {
    try {
        const response = yield fetchAsync(FormService.archivedData, action.payload);
        yield put({ type: ActionTypes.GET_ARCHIVED_DATA_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    }
    catch (e) {
        yield put({ type: ActionTypes.GET_ARCHIVED_DATA_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
    }
}

function* rejectWorkFlow(action) {
    try {
        const response = yield fetchAsync(FormService.rejectWorkFlow, action.payload);
        yield put({ type: ActionTypes.REJECT_WORKFLOW_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.REJECT_WORKFLOW_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* rejectToQa(action) {
    try {
        const response = yield fetchAsync(FormService.rejectToQa, action.payload);
        yield put({ type: ActionTypes.REJECT_TO_QA_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.REJECT_TO_QA_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getReviewInfo(action) {
    try {
        const response = yield fetchAsync(FormService.getReviewInfo, action.payload);
        yield put({ type: ActionTypes.GET_REVIEW_INFO_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_REVIEW_INFO_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getPoInfoFromExternalSource(action) {
    try {
        const response = yield fetchAsync(FormService.getPoInfoFromExternalSource, action.payload);
        yield put({ type: ActionTypes.GET_PO_INFO_FROM_EXTERNAL_SOURCE_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_PO_INFO_FROM_EXTERNAL_SOURCE_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* handleSageIntacct(action) {
    try {
        const response = yield fetchAsync(FormService.handleSageIntacct, action.payload);
        toast.success("Invoice sent to SageIntacct system");
        yield put({ type: ActionTypes.SAGE_INTACCT_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        toast.error(errorMessage)
        yield put({
            type: ActionTypes.SAGE_INTACCT_FAILURE,
            payload: {
                error: { error: errorMessage },
                dataUniqueId: action.payload.dataUniqueId
            }
        });
        console.log(e);
    }
}

function* getDeletedDoc(action) {
    try {
        const response = yield fetchAsync(FormService.getDeletedDoc, action.payload);
        yield put({ type: ActionTypes.GET_DELETED_DOCUMENT_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    }
    catch (e) {
        yield put({ type: ActionTypes.GET_DELETED_DOCUMENT_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
    }
}

function* getDelegations(action) {
    try {
        const response = yield fetchAsync(FormService.getDelegations, action.payload);
        yield put({ type: ActionTypes.GET_DELEGATIONS_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_DELEGATIONS_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}
function* getDelegation(action) {
    try {
        const response = yield fetchAsync(FormService.getDelegation, action.payload);
        yield put({ type: ActionTypes.GET_DELEGATION_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_DELEGATION_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* createDelegation(action) {
    try {
        const response = yield fetchAsync(FormService.createDelegation, action.payload);
        yield put({ type: ActionTypes.CREATE_DELEGATION_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.CREATE_DELEGATION_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getTickets(action) {
    try {
        const response = yield fetchAsync(FormService.getTickets, action.payload);
        yield put({ type: ActionTypes.GET_TICKETS_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_TICKETS_FAILURE, payload: { error: e ? e.message : 'Something went wrong' } });
        console.log(e);
    }
}

function* createTickets(action) {
    try {
        const response = yield fetchAsync(FormService.createTickets, action.payload);
        yield put({ type: ActionTypes.CREATE_TICKETS_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.CREATE_TICKETS_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* deleteDelegation(action) {
    try {
        const response = yield fetchAsync(FormService.deleteDelegation, action.payload);
        yield put({ type: ActionTypes.DELETE_DELEGATION_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.DELETE_DELEGATION_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* deleteTicket(action) {
    try {
        const response = yield fetchAsync(FormService.deleteTicket, action.payload);
        yield put({ type: ActionTypes.DELETE_TICKETS_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.DELETE_TICKETS_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* restoreDeleteDoc(action) {
    try {
        const response = yield fetchAsync(FormService.restoreDeleteDoc, action.payload);
        yield put({ type: ActionTypes.RESTORE_DELETE_DOC_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.RESTORE_DELETE_DOC_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* restoreOthersDeletedDocs(action) {
    try {
        const response = yield fetchAsync(FormService.restoreOthersDeletedDocs, action.payload);
        yield put({ type: ActionTypes.RESTORE_OTHERS_DELETED_DOC_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.RESTORE_OTHERS_DELETED_DOC_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* terminateMember(action) {
    try {
        const response = yield fetchAsync(FormService.terminateMember, action.payload);
        yield put({ type: ActionTypes.TERMINATE_MEMBER_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.TERMINATE_MEMBER_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getAllOtherDocs(action) {
    try {
        const response = yield fetchAsync(FormService.getAllOtherDocs, action.payload);
        yield put({ type: ActionTypes.GET_ALL_OTHER_DOC_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_ALL_OTHER_DOC_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getAllOthersDeletedDocs(action) {
    try {
        const response = yield fetchAsync(FormService.getAllOthersDeletedDocs, action.payload);
        yield put({ type: ActionTypes.GET_ALL_OTHER_DOC_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_ALL_OTHER_DOC_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* downloadAllOtherDocs(action) {
    try {
        const response = yield fetchAsync(FormService.downloadAllOtherDocs, action.payload);
        yield put({ type: ActionTypes.DOWNLOAD_ALL_OTHER_DOCS_SUCCESS, payload: { data: response.data, dataUniqueId: action?.payload?.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.DOWNLOAD_ALL_OTHER_DOCS_FAILURE, payload: { error: e.message, dataUniqueId: action?.payload?.dataUniqueId } });
        console.log(e);
    }
}

function* getPDFForOtherDoc(action) {
    try {
        const pdf = yield fetchAsync(FormService.getPDFForOtherDoc, action.payload);
        yield put({ type: ActionTypes.GET_PDF_FOR_OTHER_DOC_SUCCESS, payload: { pdf, dataUniqueId: action.payload.dataUniqueId, isEmailBody: action.payload.isEmailBody } });
    } catch (e) {
        let msg = "PDF document download is failed";
        if (action.payload?.payload?.field === 'emailBodyPath') {
            msg = "No Email Body as this document is uploaded manually by the user"
        }
        yield put({
            type: ActionTypes.GET_PDF_FOR_OTHER_DOC_FAILURE,
            payload: {
                error: msg || e.message,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    }
}

function* getOthersDeletedDoc(action) {
    try {
        const pdf = yield fetchAsync(FormService.getOthersDeletedDoc, action.payload);
        yield put({ type: ActionTypes.GET_PDF_FOR_OTHER_DOC_SUCCESS, payload: { pdf, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_PDF_FOR_OTHER_DOC_FAILURE, payload: { error: e.message, dataUniqueId: action.payload.dataUniqueId } });
    }
}

function* deleteOtherDocs(action) {
    try {
        const response = yield fetchAsync(FormService.deleteOtherDocs, action.payload);
        yield put({
            type: ActionTypes.DELETE_ALL_OTHER_DOCS_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.DELETE_ALL_OTHER_DOCS_FAILURE,
            payload: {
                error: e ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        });
        console.log(e);
    }
}

function* otherDocMove(action) {
    try {
        const response = yield fetchAsync(FormService.otherDocMove, action.payload);
        yield put({ type: ActionTypes.OTHER_DOC_MOVE_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        let errorCode = e.response?.error || e.response?.data?.error
        if (errorCode === "ODUP001") {
            yield put({ type: ActionTypes.OTHER_DOC_MOVE_DUPLICATE_ERROR, payload: { data: e.response, dataUniqueId: action.payload.dataUniqueId } });
        } else {
            yield put({ type: ActionTypes.OTHER_DOC_MOVE_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        }
        console.log(e);
    }
}

function* moveToDuplicates(action) {
    try {
        const response = yield fetchAsync(FormService.moveToDuplicates, action.payload);
        yield put({ type: ActionTypes.MOVE_TO_DUPLICATES_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        let errorCode = e.response?.error || e.response?.data?.error
        yield put({ type: ActionTypes.MOVE_TO_DUPLICATES_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* addComment(action) {
    try {
        const response = yield fetchAsync(FormService.addComment, action.payload);
        yield put({ type: ActionTypes.ADD_COMMENT_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.ADD_COMMENT_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* addNewEntriesByDataModel(action) {
    try {
        const response = yield fetchAsync(FormService.addNewEntriesByDataModel, action.payload);
        yield put({ type: ActionTypes.ADD_NEW_ENTRY_BY_DATAMODELS_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.ADD_NEW_ENTRY_BY_DATAMODELS_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* bulkAssign(action) {
    try {
        const response = yield fetchAsync(FormService.bulkAssign, action.payload);
        yield put({ type: ActionTypes.BULK_ASSIGN__SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        let message = e ? e.message : 'Something went wrong'
        toast.error(message)
        yield put({ type: ActionTypes.BULK_ASSIGN__FAILURE, payload: { error: message, dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getGroupsByMemberId(action) {
    try {
        const response = yield fetchAsync(FormService.getGroupsByMemberId, action.payload);
        yield put({ type: ActionTypes.GET_GROUPS_BY_MEMBER_ID_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_GROUPS_BY_MEMBER_ID_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* uploadData(action) {
    try {
        const response = yield fetchAsync(FormService.uploadData, action.payload);
        yield put({ type: ActionTypes.UPLOAD_DATA_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.UPLOAD_DATA_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}


function* getSupportingDocuments(action) {
    try {
        const response = yield fetchAsync(FormService.getSupportingDocuments, action.payload);
        yield put({ type: ActionTypes.GET_SUPPORTING_dOCUMENTS_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_SUPPORTING_dOCUMENTS_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* finalDocument(action) {

    try {
        const response = yield fetchAsync(FormService.finalDocument, action.payload);
        yield put({ type: ActionTypes.FINAL_DOCUMENT_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });

    } catch (e) {
        yield put({ type: ActionTypes.FINAL_DOCUMENT_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* duplicatesGetRequest(action) {
    try {
        const response = yield fetchAsync(FormService.duplicatesGetRequest, action.payload);
        yield put({ type: ActionTypes.DUPLICATES_GET_REQUEST_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    }
    catch (e) {
        yield put({ type: ActionTypes.DUPLICATES_GET_REQUEST_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
    }
}

function* getDuplicateDocById(action) {
    try {
        const response = yield fetchAsync(FormService.getDuplicateDocById, action.payload);
        yield put({ type: ActionTypes.GET_DUPLICATE_DOC_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    }
    catch (e) {
        yield put({ type: ActionTypes.GET_DUPLICATE_DOC_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
    }
}

function* duplicatesMoveRequest(action) {
    try {
        const response = yield fetchAsync(FormService.duplicatesMoveRequest, action.payload);
        yield put({ type: ActionTypes.DUPLICATES_MOVE_REQUEST_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    }
    catch (e) {
        yield put({ type: ActionTypes.DUPLICATES_MOVE_REQUEST_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
    }
}

function* onSplitPDF(action) {
    try {
        const response = yield fetchAsync(FormService.onSplitPDF, action.payload);
        toast.success(response.message || 'Document Successfully splitted into multiple documents')
        yield put({ type: ActionTypes.SPLIT_PDF_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    }
    catch (e) {
        yield put({ type: ActionTypes.SPLIT_PDF_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
    }
}

function* uploadSupportDocument(action) {
    try {
        const response = yield fetchAsync(FormService.uploadSupportDocument, action.payload);
        let msg = ''
        if (response.message && response.data?._id) {
            if (response.message === 'ok') {
                msg = 'Document Successfully uploaded'
            }
        }
        toast.success(msg)
        yield put({ type: ActionTypes.UPLOAD_SUPPORT_DOCUMENT_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    }
    catch (e) {
        yield put({ type: ActionTypes.UPLOAD_SUPPORT_DOCUMENT_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } });
    }
}

function* getVISData(action) {
    try {
        const response = yield fetchAsync(FormService.getVISData, action.payload);
        yield put({ type: ActionTypes.GET_VIS_DATA_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_VIS_DATA_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } })
    }
}

function* lockDocument(action) {
    try {
        const response = yield fetchAsync(FormService.lockDocument, action.payload);
        yield put({ type: ActionTypes.LOCK_DOCUMENT_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.LOCK_DOCUMENT_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } })
    }
}

function* unlockDocument(action) {
    try {
        const response = yield fetchAsync(FormService.unlockDocument, action.payload);
        yield put({ type: ActionTypes.UNLOCK_DOCUMENT_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.UNLOCK_DOCUMENT_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } })
    }
}

function* getWorkflowById(action) {
    try {
        const response = yield fetchAsync(FormService.getWorkflowById, action.payload);
        yield put({ type: ActionTypes.GET_WORKFLOW_BY_ID_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_WORKFLOW_BY_ID_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } })
    }
}

function* fetchmasterDataForDataelementUsingRules(action) {
    try {
        console.log("api called data =====")
        const response = yield fetchAsync(FormService.fetchmasterDataForDataelementUsingRules, action.payload);
        console.log(response, 'data =====')
        yield put({ type: ActionTypes.FETCH_MASTER_DATA_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.FETCH_MASTER_DATA_FAILURE, payload: { error: e ? e.message : 'Something went wrong', dataUniqueId: action.payload.dataUniqueId } })
    }
}

function* fetchProcessDashboard1Data(action) {
    try {
        const response = yield fetchAsync(FormService.fetchProcessDashboard1Data, action.payload);
        yield put({
            type: ActionTypes.FETCH_PROCESS_DASHBOARD_1_DATA_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.FETCH_PROCESS_DASHBOARD_1_DATA_FAILURE,
            payload: {
                error: e ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* fetchProcessDashboard2Data(action) {
    try {
        const response = yield fetchAsync(FormService.fetchProcessDashboard2Data, action.payload);
        yield put({
            type: ActionTypes.FETCH_PROCESS_DASHBOARD_2_DATA_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.FETCH_PROCESS_DASHBOARD_2_DATA_FAILURE,
            payload: {
                error: e ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* createAmendment(action) {
    try {
        const response = yield fetchAsync(FormService.createAmendment, action.payload);
        yield put({
            type: ActionTypes.CREATE_AMENDMENT_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.CREATE_AMENDMENT_FAILURE,
            payload: {
                error: e ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* getAmendments(action) {
    try {
        const response = yield fetchAsync(FormService.getAmendments, action.payload);
        yield put({
            type: ActionTypes.GET_AMENDMENTS_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.GET_AMENDMENTS_FAILURE,
            payload: {
                error: e ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* getNotDuplicateAPI(action) {
    try {
        const response = yield fetchAsync(FormService.getNotDuplicateAPI, action.payload);
        yield put({
            type: ActionTypes.GET_NOTDUPLICATE_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.GET_NOTDUPLICATE_FAILURE,
            payload: {
                error: e ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}
function* createContractHierarchy(action) {
    try {
        const response = yield fetchAsync(FormService.createContractHierarchy, action.payload);
        yield put({
            type: ActionTypes.CREATE_CONTRACT_HIERARCHY_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.CREATE_CONTRACT_HIERARCHY_FAILURE,
            payload: {
                error: e ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* saveDocumentHierarchy(action) {
    try {
        const response = yield fetchAsync(FormService.createContractHierarchy, action.payload);
        yield put({
            type: ActionTypes.SAVE_DOCUMENT_HIERARCHY_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.SAVE_DOCUMENT_HIERARCHY_FAILURE,
            payload: {
                error: e ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}
/**
 * @description Generator function to fetch contract hierarchies from the API.
 * Dispatches success or failure actions based on the API response.
 */
function* getContractHierarchys(action) {
    try {
        const response = yield fetchAsync(FormService.getContractHierarchys, action.payload);
        yield put({
            type: ActionTypes.GET_CONTRACT_HIERARCHYS_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    } catch (e) {
        toast.error(e.response.message);
        yield put({
            type: ActionTypes.GET_CONTRACT_HIERARCHYS_FAILURE,
            payload: {
                error: e ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* getDocumentHierarchys(action) {
    try {
        const response = yield fetchAsync(FormService.getDocumentHierarchys, action.payload);
        yield put({
            type: ActionTypes.GET_DOCUMENT_HIERARCHYS_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.GET_DOCUMENT_HIERARCHYS_FAILURE,
            payload: {
                error: e ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* getDocumentHierarchysNewV2(action) {
    try {
        const response = yield fetchAsync(FormService.getDocumentHierarchysNewV2, action.payload);
        yield put({
            type: ActionTypes.GET_DOCUMENT_HIERARCHYS_SUCCESS_NEWV2,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.GET_DOCUMENT_HIERARCHYS_FAILURE_NEWV2,
            payload: {
                error: e ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

/**
 * @description Generator function to update document hierarchies via the API.
 * Dispatches success or failure actions based on the API response.
 * Shows a success or error toast notification based on the outcome.
 */

function* updateDocHierarchys(action) {
    try {
        const response = yield fetchAsync(FormService.updateDocHierarchys, action.payload);
        toast.success(response?.data?.data?.message)
        yield put({
            type: ActionTypes.UPDATE_DOCUMENT_HIERARCHYS_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    } catch (e) {
        toast.error(e.response.data.message);
        yield put({
            type: ActionTypes.UPDATE_DOCUMENT_HIERARCHYS_FAILURE,
            payload: {
                error: e ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* getDocumentsForHierarchys(action) {
    try {
        const response = yield fetchAsync(FormService.getDocumentsForHierarchys, action.payload);
        yield put({
            type: ActionTypes.GET_DOCUMENTS_FOR_HIERARCHYS_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.GET_DOCUMENTS_FOR_HIERARCHYS_FAILURE,
            payload: {
                error: e ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* createNewDocumentPrompt(action) {
    try {
        const response = yield fetchAsync(FormService.createNewDocumentPrompt, action.payload);
        yield put({
            type: ActionTypes.SAVE_DOCUMENT_PROMPT_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.SAVE_DOCUMENT_PROMPT_FAILURE,
            payload: {
                error: e ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* getDocumentQuestionHistory(action) {
    try {
        const response = yield fetchAsync(FormService.getDocumentQuestionHistory, action.payload);
        yield put({
            type: ActionTypes.FETCH_DOCUMENT_QUESTIONS_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.FETCH_DOCUMENT_QUESTIONS_FAILURE,
            payload: {
                error: e ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* emailSendNotification(action) {
    try {
        const response = yield fetchAsync(FormService.emailSendNotification, action.payload);
        toast.success(response?.data?.message || 'Email sent sucessfully')
        yield put({
            type: ActionTypes.EMAIL_SEND_NOTIFICATIONS_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId || 'email_notification'
            }
        });
    } catch (e) {
        toast.error(e ? (e?.data?.message ? e?.data?.message : e?.message) : 'Something went wrong');
        yield put({
            type: ActionTypes.EMAIL_SEND_NOTIFICATIONS_ERROR,
            payload: {
                error: e?.message ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    }
}

function* fetchDocumentDataFromTargetDataModel(action) {
    try {
        const response = yield fetchAsync(FormService.fetchDocumentDataFromTargetDataModel, action.payload);
        yield put({
            type: ActionTypes.FETCH_DOCUMENT_DATA_FROM_TARGET_DATAMODEL_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.FETCH_DOCUMENT_DATA_FROM_TARGET_DATAMODEL_FAILURE,
            payload: {
                error: e?.message ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    }
}

function* fetchDocumentDataToSuportMapping(action) {
    try {
        const response = yield fetchAsync(FormService.fetchDocumentDataToSuportMapping, action.payload);
        yield put({
            type: ActionTypes.FETCH_DOCUMENT_DATA_TO_SUPPORT_MAPPING_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.FETCH_DOCUMENT_DATA_TO_SUPPORT_MAPPING_FAILURE,
            payload: {
                error: e?.message ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    }
}

function* fetchDocumentDataToSuportThreeWayMatch(action) {
    try {
        const response = yield fetchAsync(FormService.fetchDocumentDataToSuportThreeWayMatch, action.payload);
        yield put({
            type: ActionTypes.FETCH_DOCUMENT_DATA_TO_SUPPORT_THREE_WAY_MATCH_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.FETCH_DOCUMENT_DATA_TO_SUPPORT_THREE_WAY_MATCH_FAILURE,
            payload: {
                error: e?.message ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    }
}

function* updateMappingData(action) {
    try {
        const response = yield fetchAsync(FormService.updateMappingData, action.payload);
        yield put({
            type: ActionTypes.UPDATE_MAPPING_DATA_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        yield put({
            type: ActionTypes.UPDATE_MAPPING_DATA_FAILURE,
            payload: {
                error: e?.message ? e.message : 'Something went wrong',
                dataUniqueId: action.payload.dataUniqueId
            }
        });
    }
}

function* getDocumentAnnotations(action) {
    try {
        const response = yield fetchAsync(FormService.getDocumentAnnotations, action.payload);
        yield put({
            type: ActionTypes.GET_DOCUMENT_ANNOTATIONS_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        // toast.error(errorMessage)
        console.log("e =>", e)
        yield put({
            type: ActionTypes.GET_DOCUMENT_ANNOTATIONS_FAILURE,
            payload: {
                error: errorMessage,
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}


function* createOpportunity(action) {
    try {
        const response = yield fetchAsync(FormService.createOpportunity, action.payload);
        yield put({
            type: ActionTypes.CREATE_OPPORTUNITY_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        toast.error(errorMessage)
        // console.log("e createOpportunity Error catch =====>", errorMessage)
        yield put({
            type: ActionTypes.CREATE_OPPORTUNITY_FAILURE,
            payload: {
                error: errorMessage,
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* getAllOpportunities(action) {
    try {
        const response = yield fetchAsync(FormService.getAllOpportunities, action.payload);
        yield put({
            type: ActionTypes.GET_ALL_OPPORTUNITIES_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        // toast.error(errorMessage)
        console.log("e =>", e)
        yield put({
            type: ActionTypes.GET_ALL_OPPORTUNITIES_FAILURE,
            payload: {
                error: errorMessage,
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* getSingleOpportunity(action) {
    try {
        const response = yield fetchAsync(FormService.getSingleOpportunity, action.payload);
        yield put({
            type: ActionTypes.GET_SINGLE_OPPORTUNITY_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        // toast.error(errorMessage)
        console.log("e =>", e)
        yield put({
            type: ActionTypes.GET_SINGLE_OPPORTUNITY_FAILURE,
            payload: {
                error: errorMessage,
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* deleteOpportunity(action) {
    try {
        const response = yield fetchAsync(FormService.deleteOpportunity, action.payload);
        yield put({
            type: ActionTypes.DELETE_OPPORTUNITY_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        // toast.error(errorMessage)
        console.log("e =>", e)
        yield put({
            type: ActionTypes.DELETE_OPPORTUNITY_FAILURE,
            payload: {
                error: errorMessage,
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* updateOpportunity(action) {
    try {
        const response = yield fetchAsync(FormService.updateOpportunity, action.payload);
        yield put({
            type: ActionTypes.UPDATE_OPPORTUNITY_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        // toast.error(errorMessage)
        console.log("e =>", e)
        yield put({
            type: ActionTypes.UPDATE_OPPORTUNITY_FAILURE,
            payload: {
                error: errorMessage,
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* createActionForOpportunity(action) {
    try {
        const response = yield fetchAsync(FormService.createActionForOpportunity, action.payload);
        yield put({
            type: ActionTypes.CREATE_ACTION_FOR_OPPORTUNITY_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        // toast.error(errorMessage)
        console.log("e =>", e)
        yield put({
            type: ActionTypes.CREATE_ACTION_FOR_OPPORTUNITY_FAILURE,
            payload: {
                error: errorMessage,
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* getAllActionsOfOpportunity(action) {
    try {
        const response = yield fetchAsync(FormService.getAllActionsOfOpportunity, action.payload);
        yield put({
            type: ActionTypes.GET_ALL_ACTION_OF_OPPORTUNITY_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        // toast.error(errorMessage)
        console.log("e =>", e)
        yield put({
            type: ActionTypes.GET_ALL_ACTION_OF_OPPORTUNITY_FAILURE,
            payload: {
                error: errorMessage,
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}
/**
 * A generator function for handling the upload of an attachment for an opportunity.
 * It makes an asynchronous call to upload the attachment, handles success and failure cases,
 * and dispatches corresponding actions to update the state.
 * 
 * @param {Object} action - The action object containing the payload with attachment data.
 * @param {Object} action.payload - The payload containing data for the upload.
 * @param {string} action.payload.dataUniqueId - A unique identifier for the data related to the upload.
 * @param {FormData} action.payload.formData - The form data containing the file(s) to be uploaded.
 * 
 * @generator
 * @yields {Object} - A success action with the uploaded data.
 * @yields {Object} - A failure action with the error message if the upload fails.
 */

function* uploadAttachmentOpportunity(action) {
    try
    {
        const response = yield fetchAsync(FormService.uploadSupportAttachements, action.payload);
        let msg = ''
        if (response.message && response.data?._id) {
            if (response.message === 'ok') {
                msg = 'Attachment Successfully uploaded'
            }
        }
        toast.success(msg)
        yield put({ type: ActionTypes.UPLOAD_ATTACHEMENT_OF_OPPORTUNITY_SUCCESS, payload: { data: response.data, dataUniqueId: action.payload.dataUniqueId } });
    }
    catch(e)
    {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        toast.error(errorMessage)
        yield put({
            type: ActionTypes.UPLOAD_ATTACHEMENT_OF_OPPORTUNITY_FAILURE,
            payload: {
                error: errorMessage,
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* getSingleActionOfOpportunity(action) {
    try {
        const response = yield fetchAsync(FormService.getSingleActionOfOpportunity, action.payload);
        yield put({
            type: ActionTypes.GET_SINGLE_ACTION_OF_OPPORTUNITY_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        // toast.error(errorMessage)
        console.log("e =>", e)
        yield put({
            type: ActionTypes.GET_SINGLE_ACTION_OF_OPPORTUNITY_FAILURE,
            payload: {
                error: errorMessage,
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* updateSingleActionOfOpportunity(action) {
    try {
        const response = yield fetchAsync(FormService.updateSingleActionOfOpportunity, action.payload);
        yield put({
            type: ActionTypes.UPDATE_SINGLE_ACTION_OF_OPPORTUNITY_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        // toast.error(errorMessage)
        console.log("e =>", e)
        yield put({
            type: ActionTypes.UPDATE_SINGLE_ACTION_OF_OPPORTUNITY_FAILURE,
            payload: {
                error: errorMessage,
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* deleteSingleActionOfOpportunity(action) {
    try {
        const response = yield fetchAsync(FormService.deleteSingleActionOfOpportunity, action.payload);
        yield put({
            type: ActionTypes.DELETE_SINGLE_ACTION_OF_OPPORTUNITY_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        // toast.error(errorMessage)
        console.log("e =>", e)
        yield put({
            type: ActionTypes.DELETE_SINGLE_ACTION_OF_OPPORTUNITY_FAILURE,
            payload: {
                error: errorMessage,
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

/**
 * A generator function for handling the deletion of attachments for an opportunity.
 * It makes an asynchronous call to delete the attachments, handles success and failure cases,
 * and dispatches corresponding actions to update the state.
 * 
 * @param {Object} action - The action object containing the payload with deletion data.
 * @param {Object} action.payload - The payload containing data for the deletion.
 * @param {string} action.payload.dataUniqueId - A unique identifier for the data related to the deletion.
 * 
 * @generator
 * @yields {Object} - A success action with the response data upon successful deletion.
 * @yields {Object} - A failure action with the error message if the deletion fails.
 */

function* deleteAttachmentsForOpportunity(action) {
    try {
        const response = yield fetchAsync(FormService.deleteAttachmentsForOpportunity, action.payload);
        let msg = ''
        if (response.message && response.data) {
            if (response.message === 'ok') {
                msg = response.data.message
            }
        }
        toast.success(msg)
        yield put({
            type: ActionTypes.DELETE_ATTACHMENTS_FOR_OPPORTUNITY_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        yield put({
            type: ActionTypes.DELETE_ATTACHMENTS_FOR_OPPORTUNITY_FAILURE,
            payload: {
                error: errorMessage,
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}


function* getDocumentsFromIDsOfOpportunity(action) {
    try {
        const response = yield fetchAsync(FormService.getDocumentsFromIDsOfOpportunity, action.payload);
        yield put({
            type: ActionTypes.GET_DOCUMENTS_FROM_IDS_OF_OPPORTUNITY_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        // toast.error(errorMessage)
        console.log("e =>", e)
        yield put({
            type: ActionTypes.GET_DOCUMENTS_FROM_IDS_OF_OPPORTUNITY_FAILURE,
            payload: {
                error: errorMessage,
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}

function* resolveIntegrationExceptions(action) {
    try {
        const response = yield fetchAsync(FormService.resolveIntegrationExceptions, action.payload);
        console.log("it is response from resolveIntegrationExceptions api", response)
        if (response.error !== '0') {
            toast.error(response.message)
        } else {
            if (response.data && typeof response.data === 'string') {
                toast.success(response?.data)
            }
        }

        yield put({
            type: ActionTypes.RESOLVE_INTEGRATION_EXCEPTIONS_SUCCESS,
            payload: {
                data: response.data,
                dataUniqueId: action?.payload?.dataUniqueId
            }
        });
    } catch (e) {
        const errorMessage = e?.response?.message || e?.response?.data?.message || "Something went wrong"
        console.log("it is error from resolveIntegrationExceptions api", e, errorMessage)
        toast.error(errorMessage)
        yield put({
            type: ActionTypes.RESOLVE_INTEGRATION_EXCEPTIONS_FAILURE,
            payload: {
                error: errorMessage,
                dataUniqueId: action.payload.dataUniqueId
            }
        })
    }
}



export function* watchFormSaga() {
    yield takeEvery(ActionTypes.GET_FORMS_LOADING, getEntryForms);
    yield takeEvery(ActionTypes.GET_FORM_BY_NAME_LOADING, getEntryFormByName);
    yield takeEvery(ActionTypes.GET_SUBMITTED_DATA_BY_ID_LOADING, getSubmittedDataBySubmissionId);
    yield takeEvery(ActionTypes.SAVE_SUBMITTED_DATA_LOADING, saveSubmittedData);
    yield takeEvery(ActionTypes.UPDATE_SUBMITTED_DATA_LOADING, updateSubmittedData);
    yield takeEvery(ActionTypes.GET_COGNITIVE_SEARCH_DATA, getCognitiveSearchData);
    yield takeEvery(ActionTypes.GET_MODAL_DATA, getModelData);
    yield takeEvery(ActionTypes.SUBMIT_CONTRACT_PDF_LOADING, uploadContractPDFAction);
    yield takeEvery(ActionTypes.DOWNLOAD_PDF_ACTION, downloadContractPDFAction);
    yield takeEvery(ActionTypes.DELETE_CONTRACT_PDF, deleteContractPDFAction);
    yield takeEvery(ActionTypes.GET_COGNITIVE_SEARCH_DATA_V1, getCognitiveSearchV1Data);
    yield takeEvery(ActionTypes.SAVE_SMART_SEARCH_DATA, saveSmartSearchData);
    yield takeEvery(ActionTypes.GET_ALL_SMART_SEARCH_DATA, getAllSavedSmartSearchData);
    yield takeEvery(ActionTypes.GET_DOCUMENT_DATA, getContractData);
    yield takeEvery(ActionTypes.GET_LINEITEM_TABLES_DATA, getLineItemsTablesData);
    yield takeEvery(ActionTypes.GET_DOCUMENT_TABLES_DATA, getDocumentTablesData);
    yield takeEvery(ActionTypes.CREATE_DOCUMENT_TABLES_DATA, createDocumentTableData);
    yield takeEvery(ActionTypes.UPDATE_DOCUMENT_TABLES_DATA, updateDocumentTableData);
    yield takeEvery(ActionTypes.DELETE_DOCUMENT_TABLE_DATA, deleteDocumentTableData);
    yield takeEvery(ActionTypes.DOWNLOAD_SHEET, downloadSheet);
    yield takeEvery(ActionTypes.UPDATE_DATA_MODAL_DATA, updateDataModelData);
    yield takeEvery(ActionTypes.GET_MODAL_DROP_DOWN_DATA, getModelDataDropDown);
    yield takeEvery(ActionTypes.CREATE_DOCUMENT, createNewDocument);
    yield takeEvery(ActionTypes.GET_DATA_MODEL_BY_ID, getDataModelById);
    yield takeEvery(ActionTypes.UPDATE_DATA_MODEL_BY_ID, updateDataModelById);
    yield takeLatest(ActionTypes.SAVE_DATA_FOR_TRAINING, saveDataForTraining);
    yield takeLatest(ActionTypes.GET_TOP_SUGGESTIONS, getTopSuggestions);
    yield takeLatest(ActionTypes.ON_SEARCH_TEXT, getSearchResults);
    yield takeEvery(ActionTypes.GET_LINE_DATA_BY_ID, getLineData);
    yield takeEvery(ActionTypes.GET_BAR_DATA_BY_ID, getBarData);
    yield takeEvery(ActionTypes.GET_TASKS, getTasksList);
    yield takeEvery(ActionTypes.GET_TASK_DATA, getTaskData);
    yield takeEvery(ActionTypes.UPDATE_TASK_DATA_LOADING, updateTaskData);
    yield takeEvery(ActionTypes.DELETE_TASK_DATA, deleteTaskData);
    yield takeEvery(ActionTypes.GET_MEMBERS_LIST, getMembersList);
    yield takeEvery(ActionTypes.GET_ALL_MEMBERS_LIST, getAllMembersList);
    yield takeEvery(ActionTypes.PROCESS_AGAIN, processAgain);
    yield takeEvery(ActionTypes.GET_AUDIT_TRAILS_DATA, getAuditTrailsData);
    yield takeEvery(ActionTypes.GET_OTHERS_AUDIT_TRAILS_DATA, getOthersAuditTrailsData);
    yield takeEvery(ActionTypes.BULK_UPLOAD_PDF, bulkUploadPDF);
    yield takeEvery(ActionTypes.BULK_UPLOAD_PDF_MERGE, bulkUploadPDFFinal);
    yield takeEvery(ActionTypes.SERVICE_BUS_DATA, serviceBusAction);
    yield takeEvery(ActionTypes.SERVICE_BUS_TITLE_LIST_STATUS, serviceBusStatusUpdateTileList);
    yield takeEvery(ActionTypes.ASSIGNEE_CHANGE, changeAssigneeRequest);
    yield takeEvery(ActionTypes.COMPLETE_REVIEW, completeReviewAction);
    yield takeEvery(ActionTypes.CREATE_MEMBER, createMember);
    yield takeEvery(ActionTypes.GET_ROLES_MEMBERS, getRolesMembers);
    yield takeEvery(ActionTypes.GET_GROUPS, getGroups);
    yield takeEvery(ActionTypes.DELETE_MEMBER, deleteMember);
    yield takeEvery(ActionTypes.GET_WORKFLOW, getWorkflow);
    yield takeEvery(ActionTypes.GET_NEXT_TASKS, getNextTasks);
    yield takeEvery(ActionTypes.EXECUTE_WORKFLOW, executeWorkFlow);
    yield takeEvery(ActionTypes.INIT_WORKFLOW, initWorkFlow);
    yield takeEvery(ActionTypes.GET_NEXT_RESPONDENT, getNextRespondent);
    yield takeEvery(ActionTypes.GET_APP_CONFIG, getAppConfig);
    yield takeEvery(ActionTypes.SAVE_APP_CONFIG, saveAppMngmtConfig);
    yield takeEvery(ActionTypes.GROUPS_CREATE, createGroup);
    yield takeEvery(ActionTypes.DELETE_GROUPS, deleteGroup);
    yield takeEvery(ActionTypes.GET_CHARGE_CODE, getChargeCode);
    yield takeEvery(ActionTypes.CREATE_CHARGE_CODE, createChargeCode);
    yield takeEvery(ActionTypes.DELETE_CHARGE_CODE, deleteChargeCode);
    yield takeEvery(ActionTypes.GET_CHARGE_CODES, getChargeCodes);
    yield takeEvery(ActionTypes.GET_ARCHIVED_DATA, archivedData);
    yield takeEvery(ActionTypes.REJECT_WORKFLOW, rejectWorkFlow);
    yield takeEvery(ActionTypes.REJECT_TO_QA, rejectToQa);
    yield takeEvery(ActionTypes.GET_REVIEW_INFO, getReviewInfo);
    yield takeEvery(ActionTypes.GET_PO_INFO_FROM_EXTERNAL_SOURCE, getPoInfoFromExternalSource);
    yield takeEvery(ActionTypes.SAGE_INTACCT, handleSageIntacct);
    yield takeEvery(ActionTypes.GET_DELETED_DOCUMENT, getDeletedDoc);
    yield takeEvery(ActionTypes.GET_DELEGATIONS, getDelegations);
    yield takeEvery(ActionTypes.GET_DELEGATION, getDelegation);
    yield takeEvery(ActionTypes.CREATE_DELEGATION, createDelegation);
    yield takeEvery(ActionTypes.DELETE_DELEGATION, deleteDelegation);
    yield takeEvery(ActionTypes.GET_TICKETS, getTickets);
    yield takeEvery(ActionTypes.CREATE_TICKETS, createTickets);
    yield takeEvery(ActionTypes.DELETE_TICKETS, deleteTicket);
    yield takeEvery(ActionTypes.RESTORE_DELETE_DOC, restoreDeleteDoc);
    yield takeEvery(ActionTypes.RESTORE_OTHERS_DELETED_DOC, restoreOthersDeletedDocs);
    yield takeEvery(ActionTypes.TERMINATE_MEMBER, terminateMember);
    yield takeEvery(ActionTypes.GET_ALL_OTHER_DOC, getAllOtherDocs);
    yield takeEvery(ActionTypes.GET_ALL_OTHER_DELETED_DOC, getAllOthersDeletedDocs);
    yield takeEvery(ActionTypes.DOWNLOAD_ALL_OTHER_DOCS, downloadAllOtherDocs);
    yield takeEvery(ActionTypes.DELETE_ALL_OTHER_DOCS, deleteOtherDocs);
    yield takeEvery(ActionTypes.GET_PDF_FOR_OTHER_DOC, getPDFForOtherDoc);
    yield takeEvery(ActionTypes.GET_OTHERS_DELETED_DOC, getOthersDeletedDoc);
    yield takeEvery(ActionTypes.OTHER_DOC_MOVE, otherDocMove);
    yield takeEvery(ActionTypes.MOVE_TO_DUPLICATES, moveToDuplicates);
    yield takeLatest(ActionTypes.ADD_COMMENT, addComment);
    yield takeLatest(ActionTypes.ADD_NEW_ENTRY_BY_DATAMODELS, addNewEntriesByDataModel);
    yield takeLatest(ActionTypes.BULK_ASSIGN, bulkAssign);
    yield takeLatest(ActionTypes.GET_GROUPS_BY_MEMBER_ID, getGroupsByMemberId);
    yield takeLatest(ActionTypes.UPLOAD_DATA, uploadData);
    yield takeLatest(ActionTypes.GET_SUPPORTING_dOCUMENTS, getSupportingDocuments);
    yield takeLatest(ActionTypes.FINAL_DOCUMENT, finalDocument);
    yield takeLatest(ActionTypes.DUPLICATES_GET_REQUEST, duplicatesGetRequest);
    yield takeLatest(ActionTypes.GET_DUPLICATE_DOC, getDuplicateDocById);
    yield takeLatest(ActionTypes.DUPLICATES_MOVE_REQUEST, duplicatesMoveRequest);
    yield takeLatest(ActionTypes.SPLIT_PDF, onSplitPDF);
    yield takeLatest(ActionTypes.UPLOAD_SUPPORT_DOCUMENT, uploadSupportDocument);
    yield takeLatest(ActionTypes.GET_VIS_DATA, getVISData);
    yield takeLatest(ActionTypes.LOCK_DOCUMENT, lockDocument);
    yield takeLatest(ActionTypes.UNLOCK_DOCUMENT, unlockDocument);
    yield takeLatest(ActionTypes.GET_WORKFLOW_BY_ID, getWorkflowById);
    yield takeLatest(ActionTypes.REJECT_REVIEW, rejectReviewAction);
    yield takeEvery(ActionTypes.FETCH_MASTER_DATA, fetchmasterDataForDataelementUsingRules)
    yield takeEvery(ActionTypes.GET_HISTORY_DATA, getHistoryData);
    yield takeEvery(ActionTypes.FETCH_PROCESS_DASHBOARD_1_DATA, fetchProcessDashboard1Data);
    yield takeEvery(ActionTypes.FETCH_PROCESS_DASHBOARD_2_DATA, fetchProcessDashboard2Data);
    yield takeEvery(ActionTypes.CREATE_AMENDMENT, createAmendment);
    yield takeEvery(ActionTypes.GET_AMENDMENTS, getAmendments);
    yield takeEvery(ActionTypes.CREATE_CONTRACT_HIERARCHY, saveDocumentHierarchy);
    yield takeEvery(ActionTypes.SAVE_DOCUMENT_HIERARCHY, createContractHierarchy);
    yield takeEvery(ActionTypes.GET_CONTRACT_HIERARCHYS, getContractHierarchys);
    yield takeEvery(ActionTypes.GET_DOCUMENT_HIERARCHYS, getDocumentHierarchys);
    yield takeEvery(ActionTypes.GET_DOCUMENT_HIERARCHYS_NEWV2, getDocumentHierarchysNewV2);
    yield takeEvery(ActionTypes.GET_DOCUMENTS_FOR_HIERARCHYS, getDocumentsForHierarchys);
    yield takeEvery(ActionTypes.SAVE_DOCUMENT_PROMPT, createNewDocumentPrompt);
    yield takeEvery(ActionTypes.FETCH_DOCUMENT_QUESTIONS, getDocumentQuestionHistory);
    yield takeEvery(ActionTypes.EMAIL_SEND_NOTIFICATIONS, emailSendNotification);
    yield takeEvery(ActionTypes.FETCH_DOCUMENT_DATA_FROM_TARGET_DATAMODEL, fetchDocumentDataFromTargetDataModel)
    yield takeEvery(ActionTypes.FETCH_DOCUMENT_DATA_TO_SUPPORT_MAPPING, fetchDocumentDataToSuportMapping)
    yield takeEvery(ActionTypes.UPDATE_MAPPING_DATA, updateMappingData)
    yield takeEvery(ActionTypes.FETCH_DOCUMENT_DATA_TO_SUPPORT_THREE_WAY_MATCH, fetchDocumentDataToSuportThreeWayMatch)
    yield takeEvery(ActionTypes.GET_DOCUMENT_ANNOTATIONS, getDocumentAnnotations);
    yield takeEvery(ActionTypes.GET_CURRENT_STEP_RESPONDENT, getCurrentStepRespondents);
    yield takeEvery(ActionTypes.GET_NOTDUPLICATE, getNotDuplicateAPI);
    yield takeEvery(ActionTypes.CREATE_OPPORTUNITY, createOpportunity);
    yield takeEvery(ActionTypes.GET_ALL_OPPORTUNITIES, getAllOpportunities);
    yield takeEvery(ActionTypes.GET_SINGLE_OPPORTUNITY, getSingleOpportunity);
    yield takeEvery(ActionTypes.DELETE_OPPORTUNITY, deleteOpportunity);
    yield takeEvery(ActionTypes.UPDATE_OPPORTUNITY, updateOpportunity);
    yield takeEvery(ActionTypes.CREATE_ACTION_FOR_OPPORTUNITY, createActionForOpportunity);
    yield takeEvery(ActionTypes.GET_ALL_ACTION_OF_OPPORTUNITY, getAllActionsOfOpportunity);
    yield takeEvery(ActionTypes.GET_SINGLE_ACTION_OF_OPPORTUNITY, getSingleActionOfOpportunity);
    yield takeEvery(ActionTypes.UPDATE_SINGLE_ACTION_OF_OPPORTUNITY, updateSingleActionOfOpportunity);
    yield takeEvery(ActionTypes.DELETE_SINGLE_ACTION_OF_OPPORTUNITY, deleteSingleActionOfOpportunity);
    yield takeEvery(ActionTypes.GET_DOCUMENTS_FROM_IDS_OF_OPPORTUNITY, getDocumentsFromIDsOfOpportunity);
    yield takeEvery(ActionTypes.RESOLVE_INTEGRATION_EXCEPTIONS, resolveIntegrationExceptions);
    yield takeEvery(ActionTypes.UPLOAD_ATTACHEMENT_OF_OPPORTUNITY, uploadAttachmentOpportunity);
    yield takeEvery(ActionTypes.DELETE_ATTACHMENTS_FOR_OPPORTUNITY, deleteAttachmentsForOpportunity);
    yield takeEvery(ActionTypes.UPDATE_DOCUMENT_HIERARCHYS, updateDocHierarchys);
}

export default watchFormSaga;